<template>
    <div id="bg">
        <div class="search">
            <div class="search-box">
                <span class="search-box-title">订单号：</span>
                <el-input v-model="search.order" placeholder="请输入订单号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">客户账号：</span>
                <el-input v-model="search.username" placeholder="请输入客户账号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">银商账号：</span>
                <el-input v-model="search.ys_account" placeholder="请输入银商账号" size="medium" clearable></el-input>
            </div>
           
            <div class="search-box">
                <el-button type="primary" size="medium" @click="(pager.page = 1),getList()">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
            <el-table-column prop="order" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="" label="客户信息" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                [{{ scope.row.uid }}]{{ scope.row.username }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="银商信息" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                [{{ scope.row.ys_uid }}]{{ scope.row.ys_username }}
              </template>
            </el-table-column>
            <el-table-column prop="addtime" label="会话时间" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <p>{{timeToDate(scope.row.addtime)}}</p>
                <p>{{timeToDate(scope.row.pay_time)}}</p>
              </template>
            </el-table-column>
          <el-table-column prop="status" label="会话状态" align="center" :show-overflow-tooltip="true">
            <template #default="scope">
              <span>{{getStatus(scope.row)}}</span>
            </template>
          </el-table-column>
            <el-table-column label="操作" width="250px" align="center">
                <template #default="scope">
                    <span class="operation" @click="messageLog(scope.row)">聊天记录</span>
                </template>
            </el-table-column>
        </el-table>
        <page :pager="pager" @query="getList()" />

        <!-- 聊天记录弹窗 -->
        <el-dialog custom-class="dialog" :title="title" v-model="dialog" width="700px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
          <div v-for="(item, i) in messageLogData" :key="i">
            <div v-if="item.from == 1">
              <!-- 发送给用户 -->
              <span style="color: blue">[{{ row.ys_uid }}]{{ row.ys_username }}</span><span style="margin-left: 30px;">{{ timeToDate(item.addtime) }}</span>
              <div v-for="(val, j) in item" :key="j">
                <div v-if="val.msg_type == 'TIMTextElem'">
                  <div v-html="val.msg_content.text"></div>
                </div>
                <div v-if="val.msg_type == 'TIMFinishElem'">
                  <div v-html="val.msg_content.title"></div>
                </div>
                <div v-if="val.msg_type == 'TIMPayTypeElem'">
                  <p>{{ val.msg_content.title }}-{{ row.price }}元</p>
                  <p>请选择您的付款方式</p>
                  <div>
                    <div v-for="(val2, k) in val.msg_content.pay_info" :key="k" style="display:inline-block;margin-left: 20px;">
                      <img :src="require('@/assets/recharge/' + val2.icon)" />
                      <p>{{ val2.title }}</p>
                    </div>
                  </div>
                </div>
                <div v-if="val.msg_type == 'TIMImageElem'">
                  <el-image 
                    :key="n" 
                    style="width: 150px; height: 150px"
                    :src="assetsUrl(val.msg_content.images[0].url)" 
                    :preview-src-list=[assetsUrl(val.msg_content.images[1].url)]>
                  </el-image>
                </div>
                <div v-if="val.msg_type == 'TIMPayDetailElem'">
                  <div>
                    <p>{{ val.msg_content.title }}</p>
                    <div v-if="val.msg_content.pay_type != 3">
                      <el-image 
                        :key="n" 
                        style="width: 150px; height: 150px"
                        :src="assetsUrl(val.msg_content.qrcode_url)" 
                        :preview-src-list=[assetsUrl(val.msg_content.qrcode_url)]>
                      </el-image>
                    </div>
                    <div v-else>
                        <div>
                          <span>卡号：{{ val.msg_content.account }}</span>
                        </div>
                        <div>
                          <span>银行名称：{{ val.msg_content.type_name }}</span>
                        </div>
                        <div>
                          <span>真实姓名：{{ val.msg_content.real_name }}</span>
                        </div>
                        <div>
                          <span>开户地址：{{ val.msg_content.addr }}</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <span style="color: green">[{{ row.uid }}]{{ row.username }}</span><span style="margin-left: 30px;">{{ timeToDate(item.addtime) }}</span>
              <div v-for="(val, j) in item" :key="j">
                <div v-if="val.msg_type == 'TIMTextElem'">
                  <div v-html="val.msg_content.text"></div>
                </div>
                <div v-if="val.msg_type == 'TIMFinishElem'">
                  <div v-html="val.msg_content.title"></div>
                </div>
                <div v-if="val.msg_type == 'TIMPayTypeElem'">
                  <p>{{ val.msg_content.title }}</p>
                  <p>请选择您的付款方式</p>
                  <div>
                    <div v-for="(val2, k) in val.msg_content.pay_info" :key="k" style="display:inline-block;margin-left: 20px;">
                      <img :src="require('@/assets/recharge/' + val2.icon)" />
                      <p>{{ val2.title }}</p>
                    </div>
                  </div>
                </div>
                <div v-if="val.msg_type == 'TIMImageElem'">
                  <el-image 
                    :key="n" 
                    style="width: 150px; height: 150px"
                    :src="assetsUrl(val.msg_content.images[0].url)" 
                    :preview-src-list=[assetsUrl(val.msg_content.images[1].url)]>
                  </el-image>
                </div>
                <div v-if="val.msg_type == 'TIMPayDetailElem'">
                  <div>
                    <p>{{ val.msg_content.title }}</p>
                    <div v-if="val.msg_content.pay_type != 3">
                      <el-image 
                        :key="n" 
                        style="width: 150px; height: 150px"
                        :src="assetsUrl(val.msg_content.qrcode_url)" 
                        :preview-src-list=[assetsUrl(val.msg_content.qrcode_url)]>
                      </el-image>
                    </div>
                    <div v-else>
                        <div>
                          <span>卡号：{{ val.msg_content.account }}</span>
                        </div>
                        <div>
                          <span>银行名称：{{ val.msg_content.type_name }}</span>
                        </div>
                        <div>
                          <span>真实姓名：{{ val.msg_content.real_name }}</span>
                        </div>
                        <div>
                          <span>开户地址：{{ val.msg_content.addr }}</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template #footer>
          <span class="dialog-footer"></span>
        </template>
        </el-dialog>

    </div>
  </template>
  
  <script>
  import httpClient from "@/config/httpClient";
  import { getList, getMessageLog } from "@/api/ys/messageLog";
  import page from "@/components/page";
  export default {
    name: "messageLog",
    components: {
        page,
    },
    data() {
      return {
        search: {
          //搜索
          order: "",
          username: "",
          ys_account: ""
        },
        pager: { total: 0, page: 1, rows: 10 },
        tableData: [], //数据
        row: [],
        dialog: false, // 聊天记录框
        title: "", // 聊天记录标题
        messageLogData: [], // 聊天记录数据
        form: {},
      };
    },
    computed: {},
    created() {},
    mounted() {
      this.getList();
      this.getSysInfo();
    },
    methods: {
      timeToDate(time){
        if (time == 0){
          return "-"
        }
        return this.$common.timeToDate(time)
      },
      getStatus(row){
        // 解析会话状态
        if(row.status == 0 && row.expire < ((new Date()).getTime() / 1000)){
          return "已超时";
        }
        if(row.status == 0){
          return "进行中";
        }else if(row.status == 1){
          return "已完成";
        }else if (row.status == 2){
          if(row.remark == "用户取消订单"){
            return row.remark
          }else{
            return "银商取消订单"
          }
        }else{
          return "-"
        }
      },
      //获取消息列表
      getList() {
        getList([
            { key: "page", val: this.pager.page - 1 },
            { key: "row", val: this.pager.rows },
            { key: "orderNum", val: this.search.order },
            { key: "account", val: this.search.username },
            { key: "ysAccount", val: this.search.ys_account },
        ])
          .then((res) => {
            if (res.code == 0) {
              if (this.pager.page == 1){
                this.pager.total = res.data.total;
              }
                this.tableData = res.data.list;
            } else {
                this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      //获取系统设置信息
      getSysInfo() {
        httpClient("systemSetting").post({}).then((res) => {
            if (res.code == 0) {
              for (const key in res.data) {
                this.form[key] = res.data[key];
              }
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

       /**
       * 判断资源地址是否带http
       * @param {*} state
       * @param {*} banner 图片、视频地址
       */
      assetsUrl(banner) {
        if(!banner) return ''
        if (banner.indexOf("http") > -1) return banner;
        console.log(this.form.oss_url.split(";")[0] + banner);
        return this.form.oss_url.split(";")[0] + banner;
      },

      // 获取聊天记录
      messageLog(row) {
        this.row = row;
        getMessageLog([
          { key: "orderNum", val: row.order },
        ]).then((res) => {
            if (res.code == 0) {
                res.data.forEach(item => {
                  item.msg_log = JSON.parse(item.msg_log);
                  if(item.msg_log.msg_type == "TIMTextElem"){
                    item.msg_log.msg_content.text = decodeURI(item.msg_log.msg_content.text)
                  }
                })
                this.messageLogData = res.data;
                this.title = "[" + row.uid + "]" + row.username + " - " + "[" + row.ys_uid+ "]" + row.ys_username + " 的聊天记录";
                this.dialog = true;
            } else {
                this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>